import { useEffect, forwardRef, useImperativeHandle } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Portal } from 'react-portal';
import css from '../styles/modules/Modal.module.scss';

const Modal = forwardRef(({ children, title, button, action, visibility, onOpen, onClose, style }, ref) => {
  /** HANDLERS */
  const showModal = () => {
    if (onOpen)
      onOpen();

    setTimeout(() => disableBodyScroll(document.querySelector(`.${css.wrapper}`)));
  };
  const closeModal = () => {
    if (onClose)
      onClose();

    setTimeout(() => clearAllBodyScrollLocks());
  };
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27)
      closeModal();
  };
  const handleBackgroundClick = (e) => {
    if (e.target.className === css.wrapper)
      closeModal();
  };

  /** HOOKS */
  useImperativeHandle(ref, () => ({
    show() {
      showModal();
    },
    hide() {
      closeModal();
    },
    children() {
      return children;
    }
  }));
  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return () => document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
  }, []);

  return (
    <Portal>
      <AnimatePresence>
        {visibility && (
          <motion.div
            className={css.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
          >
            <div className={css.wrapper} onClick={handleBackgroundClick} style={style}>
              
              <motion.div
                className={css.modal}
                initial={window.innerWidth < 576 ? { bottom: -700 } : { opacity: 0, y: '100%' }}
                animate={
                  window.innerWidth < 576
                    ? { bottom: 0, transition: { delay: 0.2 } }
                    : { opacity: 1, y: '0%', transition: { delay: 0.2 } }
                }
                exit={{ opacity: 0 }}
              >
                <motion.button
                className={css.modalClose}
                type="button"
                onClick={closeModal}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.5 } }}
                exit={{ opacity: 0 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </motion.button>
                {title && <div className={css.modalTitle}>{title}</div>}
                {children && <div className={css.modalContent}>{children}</div>}
                {button && (
                  <button
                    className={[css.modalButton, css.fullWidth].join(' ')}
                    title="Закрыть"
                    type="button"
                    onClick={action}
                  >
                    {button}
                  </button>
                )}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
});

Modal.displayName = 'Modal';

export default Modal;
